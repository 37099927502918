import axios from "axios";
import config from '../config/config'
import { setUser } from "./auth"

export const updateUserPreferences = ( data ) => {
    axios.post(config.host + "/tribecaapi/updatepreferences", data)
            .then(response => {
                let _response_data = response.data;
                setUser(_response_data);
            }).catch(function (error) {
    });
}

export const updateUser = ( data ) => {
    axios.post(config.host + "/tribecaapi/accountupdate", data)
            .then(response => {
                let _response_data = response.data;
                if(_response_data !== 0){
                    setUser(_response_data);
                    if(typeof window !== 'undefined'){
                        window.location.href = "/account/";
                    }
                }
            }).catch(function (error) {
    });
}

export const doRegister = ( data ) => {    
    var email_exist_msg = 'User with email address already exists';
    var general_error_msg = 'There has been an error processing your request, please try again later.';
    axios.post(config.host + "/tribecaapi/accountregister", data).then(response => {
        let _response_data = response.data;
        let data_type = (typeof _response_data);
        if(_response_data === 0){
            document.getElementById("error-message").innerHTML = '<p>' + general_error_msg + '</p>';
            document.getElementById("error-message").style.display = "block";
            var registerform = document.getElementById("error-message");
            if(typeof window !== 'undefined'){
                const y = registerform.getBoundingClientRect().top + window.scrollY - 100;
                window.scroll({
                  top: y,
                  behavior: 'smooth'
                });
            }else{
                registerform.scrollIntoView();
            }
            return false;
        }else if(_response_data === 2){
            document.getElementById("error-message").innerHTML = '<p>' + email_exist_msg + '</p>';
            document.getElementById("error-message").style.display = "block";
            var registerform = document.getElementById("error-message");
            if(typeof window !== 'undefined'){
                const y = registerform.getBoundingClientRect().top + window.scrollY - 100;
                window.scroll({
                  top: y,
                  behavior: 'smooth'
                });
            }else{
                registerform.scrollIntoView();
            }  
            return false;
        }else if(data_type === 'object'){
            setUser(_response_data);
            if(typeof window !== 'undefined'){
                window.location.href = "/account/";
            }
        }
    }).catch(function (error) {
        
    });    
}

export const updatePin = ( data ) => {
    axios.post(config.host + "/tribecaapi/updatepin", data)
        .then(response => {
        }).catch(function (error) {
    });
}

export const getRecentPages = () => {
    if (typeof window !== "undefined" && window.localStorage) {
        return window.localStorage.getItem('recentPages') ? JSON.parse(window.localStorage.getItem('recentPages')) : []
    }
    return []
}

export const setRecentPages = pages => {
    if (typeof window !== "undefined" && window.localStorage) {
        window.localStorage.setItem('recentPages', JSON.stringify(pages.slice(0, 50)))
    }
}